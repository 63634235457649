import { Mark, mergeAttributes, SingleCommands } from "@tiptap/react";

export type ReviseType = {
  revision: "revision";
  summary: "summary";
};

export interface RevisionMarkOptions {
  HTMLAttributes: {
    revision?:
      | {
          type: ReviseType["revision"];
          data: {
            [question: string]: {
              answer: string;
              id: string;
              retained?: string;
              forgotten?: string;
            };
          };
        }
      | {
          type: ReviseType["summary"];
          data: string;
        };
  };
}
export type RevisionCardColorsType = keyof typeof RevisionCardColors;

export const RevisionCardColors = {
  blue: {
    label: "blue",
    value: "--blue",
  },
  orange: {
    label: "orange",
    value: "--orange",
  },
  red: {
    label: "red",
    value: "--red",
  },
  green: {
    label: "green",
    value: "--green",
  },
  yellow: {
    label: "yellow",
    value: "--yellow",
  },
  purple: {
    label: "purple",
    value: "--purple",
  },
  pink: {
    label: "pink",
    value: "--pink",
  },
  gray: {
    label: "gray",
    value: "--gray",
  },
} as const;

export const DEFAULT_REVISION_CARD_COLOR: RevisionCardColorsType = Object.keys(
  RevisionCardColors
)[0] as RevisionCardColorsType;

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    reviseMark: {
      setReviseMark: (
        color: RevisionCardColorsType,
        attributes: RevisionMarkOptions["HTMLAttributes"]["revision"],
        revisionId: string
      ) => ReturnType;

      unsetReviseMark: () => ReturnType;
    };
  }
}

export const ReviseMark = Mark.create<RevisionMarkOptions>({
  name: "reviseMark",
  priority: 10000000000,
  keepOnSplit: false,
  excludes: "code",
  exitable: true,

  addAttributes() {
    return {
      class: {
        default: `revision-mark before:absolute before:content-[""] before:h-[4px] before:w-[12px] md:before:-left-6 before:rounded-sm`,
        parseHTML: (element) => {
          return element.getAttribute("class");
        },
        renderHTML: (attributes) => {
          if (!attributes.class) {
            return {};
          }
          return {
            class: attributes.class + ` revision-mark2`,
            dataTooltip: "revision",
          };
        },
      },
      color: {
        default: null,
        parseHTML: (element) => {
          return element.getAttribute("color");
        },
      },
      revision: {
        default: null,
        parseHTML: (element) => {
          return element.getAttribute("revision");
        },
        renderHTML: (attributes) => {
          if (!attributes.revision) {
            return [];
          }
          return {
            revision: attributes.revision,
          };
        },
      },
      revisionId: {
        default: null,
        parseHTML: (element) => {
          return element.getAttribute("revisionId");
        },
        renderHTML: (attributes) => {
          if (!attributes.revisionId) {
            return [];
          }
          return {
            id: attributes.revisionId,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "span",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },

  addCommands() {
    return {
      setReviseMark:
        (
          color: RevisionCardColorsType,
          revision: RevisionMarkOptions["HTMLAttributes"]["revision"],
          revisionId: string
        ) =>
        ({ commands }: { commands: SingleCommands }) => {
          return commands.setMark(this.name, {
            revision: revision,
            color,
            revisionId,
          });
        },
      unsetReviseMark:
        () =>
        ({ commands }: { commands: SingleCommands }) => {
          return commands.unsetMark(this.name);
        },
    } as any;
  },
});
