import { Mark } from "@tiptap/pm/model";
import { Editor } from "@tiptap/react";
import { X } from "lucide-react";
import { useEffect, useState } from "react";
import { Button } from "../../Button";
import { CardStack } from "../../CardStack";
import { Dialog, DialogContent } from "../../Dialog";

function findRevisionMarkAndUpdate({
  editor,
  id,
  attributes,
  revisionId,
}: {
  editor: Editor | null;
  id: string;
  attributes: Record<string, unknown>;
  revisionId: string;
}) {
  if (!editor) return;

  const { state, view } = editor;
  const { doc, tr } = state;
  let found = false;

  try {
    const traverse = (node: any, pos: any) => {
      if (pos === 0) return;
      if (node.marks?.length > 0 && node.type.name === "text") {
        const reviseMark = node.marks.find(
          (mark: Mark) =>
            mark.attrs &&
            mark.attrs.revisionId === revisionId &&
            mark.type.name === "reviseMark"
        );

        if (reviseMark && reviseMark?.attrs?.revision?.type === "revision") {
          found = true;

          // Remove the existing mark
          tr.removeMark(pos, pos + node.nodeSize, reviseMark.type);

          const questionById = Object.entries(
            reviseMark.attrs.revision.data
          ).reduce((acc, [key, value]) => {
            if ((value as any)?.id === id) {
              acc = key;
            }
            return acc;
          }, "");

          // Create a new mark with updated attributes
          const updatedMark = reviseMark.type.create({
            ...reviseMark.attrs,
            revision: {
              ...reviseMark.attrs.revision,
              //
              data: {
                [questionById]: {
                  ...reviseMark.attrs.revision.data[questionById],
                  ...attributes,
                },
              },
            },
          });

          // Add the updated mark
          tr.addMark(pos, pos + node.nodeSize, updatedMark);
        }
      }

      node.content?.content?.forEach((node: any) => {
        traverse(node, pos);
      });
    };

    doc.descendants((node, position) => {
      // calculate positions of each text node correctly.
      traverse(node, position);
    });

    if (found) {
      view.dispatch(tr);
    } else {
      console.warn(`Element with id "${id}" not found.`);
    }
  } catch (e) {
    console.warn(e);
  }
}

export const ReviseCards = ({
  flashcards = [],
  open,
  setOpen,
  editor,
}: {
  flashcards: {
    question: string;
    answer: string;
    color: string;
    id: string;
    retained: number;
    forgotten: number;
    revisionId: string;
  }[];
  open: boolean;
  setOpen: (open: boolean) => void;
  editor: Editor | null;
}) => {
  const [activeCard, setActiveCard] = useState(null);

  useEffect(() => {
    if (!activeCard && flashcards?.length > 0) {
      setActiveCard(flashcards?.[0]);
    }
  }, [flashcards, activeCard]);

  const activeCardIndex = flashcards.reduce((acc, card, index) => {
    if (card.id === activeCard?.id) {
      acc = index;
    }
    return acc;
  }, -1);

  const cardsToDisplay = flashcards.slice(activeCardIndex, activeCardIndex + 3);

  const handleNextCard = () => {
    const nextCard = flashcards[activeCardIndex + 1];
    if (nextCard) {
      setActiveCard(nextCard);
    }
  };

  const handlePreviousCard = () => {
    const previousCard = flashcards[activeCardIndex - 1];
    if (previousCard) {
      setActiveCard(previousCard);
    }
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
      }}
    >
      <DialogContent
        className="p-6 pb-0 h-auto focus-visible:outline-none z-[50000] !bg-transparent shadow-none border-none"
        closeButton={null}
      >
        <CardStack
          allItems={flashcards}
          items={cardsToDisplay.map((revisionCard) => {
            return {
              id: revisionCard.id,
              question:
                revisionCard.id === revisionCard.question
                  ? ""
                  : revisionCard.question,
              answer: revisionCard.answer,
              color: revisionCard.color,
              retained: revisionCard.retained,
              forgotten: revisionCard.forgotten,
              revisionId: revisionCard.revisionId,
            };
          })}
          activeCardIndex={activeCardIndex}
          updateReviseMark={(revisionId, id, attributes) =>
            findRevisionMarkAndUpdate({ editor, revisionId, id, attributes })
          }
          handleNextCard={handleNextCard}
          handlePreviousCard={handlePreviousCard}
          onClose={
            <div className="absolute z-[50000] top-6 right-5 flex flex-col">
              <Button
                size="icon"
                variant="ghost"
                className="h-7 w-7"
                onClick={() => {
                  setOpen(false);
                  setTimeout(() => {
                    setActiveCard(null);
                  }, 200);
                }}
              >
                <X className="h-4 w-4" />
                <span className="sr-only">Close</span>
              </Button>
            </div>
          }
        />
      </DialogContent>
    </Dialog>
  );
};
