import { Editor } from "@tiptap/react";
import { ChevronRight, Sparkle } from "lucide-react";
import { FC, useCallback } from "react";
import { v4 } from "uuid";
import { z } from "zod";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../ui-components/Accordion";
import { Button } from "../../../ui-components/Button";
import {
  Dialog,
  DialogContent,
  DialogPortal,
  DialogTitle,
} from "../../../ui-components/Dialog";
import { LucideIcon } from "../../../ui-components/LucideIcon";
import {
  DEFAULT_REVISION_CARD_COLOR,
  RevisionCardColorsType,
  RevisionMarkOptions,
} from "../../extensions/revise-marks/Revise.mark";
import { revisionItemSchema } from "../SelectionMenu";
import { MarkerForm } from "./MarkerForm";
import { AIMarkerGeneratorWrapper } from "./OpenaiMarkerGenerator";

export const RevisionDialogs: FC<{
  dialog: "create" | "preview" | "ai" | null;
  setDialog: (dialog: "create" | "preview" | "ai" | null) => void;
  editor: Editor;
  setSelectionType: (type: "reviseMark" | null) => void;
  selectedText: string;
}> = ({ dialog, setDialog, editor, setSelectionType, selectedText }) => {
  const attributes = editor.getAttributes("reviseMark");

  const onSubmitForm = useCallback(
    (values: z.infer<typeof revisionItemSchema>) => {
      editor
        .chain()
        .unsetReviseMark()
        .setReviseMark(
          (values?.color ||
            DEFAULT_REVISION_CARD_COLOR) as RevisionCardColorsType,
          {
            type: "revision",
            data: {
              [values?.question]: {
                answer: values?.answer,
                id: v4(),
                retained: "0",
                forgotten: "0",
              },
            },
          },
          v4()
        )
        .setTextSelection({
          from: 1,
          to: 1,
        })
        .run();

      // setIsOpen(false);
      setSelectionType(null);
    },
    [editor, setSelectionType]
  );

  const addNewMarker = ({
    question,
    answer,
    color,
  }: {
    question: string;
    answer: string;
    color: RevisionCardColorsType;
  }) => {
    onSubmitForm({ question, answer, color });
  };

  const onSubmitAiMarkers = useCallback(
    (values: { question: string; answer: string }[]) => {
      editor
        .chain()
        .unsetReviseMark()
        .setReviseMark(
          DEFAULT_REVISION_CARD_COLOR as RevisionCardColorsType,
          {
            type: "revision",
            data: values.reduce((acc, item) => {
              return {
                ...acc,
                [item.question]: {
                  answer: item.answer,
                  id: v4(),
                  retained: "0",
                  forgotten: "0",
                },
              };
            }, {}),
          },
          v4()
        )
        .run();

      // setIsOpen(false);
      setSelectionType(null);
    },
    [editor, setSelectionType]
  );

  return (
    <>
      {dialog === "preview" && (
        <Dialog
          open
          onOpenChange={(open) => {
            if (!open) {
              setDialog(null);
              setSelectionType(null);
            }
          }}
        >
          <DialogPortal>
            <DialogContent>
              <DialogTitle>
                <div className="flex flex-row flex-wrap items-center gap-1">
                  <span className="text-[14px] font-normal p-1 rounded-md bg-backgroundColors-gray-light dark:bg-backgroundColors-gray-dark text-textColors-secondary-light dark:text-textColors-secondary-dark">
                    Reflect
                  </span>
                  <LucideIcon icon={ChevronRight} size={16} />
                  <span className="text-[14px] font-normal">Preview</span>
                </div>
              </DialogTitle>
              <div className="flex flex-col justify-between h-[450px] overflow-auto mt-6">
                <div className="flex flex-col gap-4">
                  <p className="max-h-32 text-sm leading-6 overflow-auto p-2 rounded-md bg-backgroundColors-gray-light dark:bg-backgroundColors-gray-dark text-text-tertiary-light dark:text-text-tertiary-dark">
                    {selectedText}
                  </p>
                  <Accordion type="single" collapsible className="pb-24">
                    {Object.entries(
                      (attributes?.revision
                        ?.data as RevisionMarkOptions["HTMLAttributes"]["revision"]) ||
                        {}
                    ).length === 0 ? (
                      <div className="h-48 grid place-items-center mt-6">
                        <div className="flex flex-col items-center gap-2">
                          <LucideIcon icon={Sparkle} size={24} />
                          <span className="text-textColors-muted-light dark:text-textColors-muted-dark">
                            No reflections found.
                          </span>
                        </div>
                      </div>
                    ) : (
                      Object.entries(
                        (attributes?.revision
                          ?.data as RevisionMarkOptions["HTMLAttributes"]["revision"]) ||
                          {}
                      )?.map(([question, data], index) => {
                        return (
                          <AccordionItem
                            key={index}
                            value={`${index}`}
                            className="border-b-0"
                          >
                            <AccordionTrigger>
                              <div className="flex items-start gap-1">
                                <span className="text-left">
                                  {question || `--`}
                                </span>
                              </div>
                            </AccordionTrigger>
                            <AccordionContent className="border-l border-border-primary-light dark:border-border-primary-dark">
                              <div className="flex items-start flex-wrap gap-1 pl-4">
                                {data.answer}
                              </div>
                            </AccordionContent>
                          </AccordionItem>
                        );
                      })
                    )}
                  </Accordion>
                </div>
                <div className="flex items-center justify-end gap-4 absolute bottom-8 right-8">
                  <Button
                    onClick={() => {
                      setDialog(null);
                      setSelectionType(null);
                      editor
                        .chain()
                        .extendMarkRange("reviseMark")
                        .unsetReviseMark()
                        .setTextSelection({
                          from: 1,
                          to: 1,
                        })
                        .run();
                    }}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </DialogContent>
          </DialogPortal>
        </Dialog>
      )}
      {dialog === "create" && (
        <Dialog
          open
          onOpenChange={(open) => {
            if (!open) {
              setDialog(null);
              setSelectionType(null);
            }
          }}
        >
          <DialogPortal>
            <DialogContent>
              <DialogTitle>
                <div className="flex flex-row flex-wrap items-center gap-1">
                  <span className="text-[14px] font-normal p-1 rounded-md bg-backgroundColors-gray-light dark:bg-backgroundColors-gray-dark text-textColors-secondary-light dark:text-textColors-secondary-dark">
                    Reflect
                  </span>
                  <LucideIcon icon={ChevronRight} size={16} />
                  <span className="text-[14px] font-normal">New revision</span>
                </div>
              </DialogTitle>
              <MarkerForm
                onSubmit={addNewMarker}
                onDelete={() => {
                  setDialog(null);
                  setSelectionType(null);
                  editor
                    .chain()
                    .extendMarkRange("reviseMark")
                    .unsetReviseMark()
                    .setTextSelection({
                      from: 1,
                      to: 1,
                    })
                    .run();
                }}
                selectedText={selectedText}
              />
            </DialogContent>
          </DialogPortal>
        </Dialog>
      )}
      {dialog === "ai" && (
        <Dialog
          open
          onOpenChange={(open) => {
            if (!open) {
              setDialog(null);
              setSelectionType(null);
            }
            // setIsOpen(open);
          }}
        >
          <DialogPortal>
            <DialogContent className="pr-0">
              <DialogTitle>
                <div className="flex flex-row flex-wrap items-center gap-1">
                  <span className="text-[14px] font-normal p-1 rounded-md bg-backgroundColors-gray-light dark:bg-backgroundColors-gray-dark text-textColors-secondary-light dark:text-textColors-secondary-dark">
                    Reflect
                  </span>
                  <LucideIcon icon={ChevronRight} size={16} />
                  <span className="text-[14px] font-normal">AI</span>
                </div>
              </DialogTitle>
              <div className="h-[450px] flex-grow">
                <AIMarkerGeneratorWrapper
                  onSubmit={(values) => onSubmitAiMarkers(values)}
                  onDelete={() => {
                    // setIsOpen(false);
                    setDialog(null);
                    setSelectionType(null);
                    editor
                      .chain()
                      .extendMarkRange("reviseMark")
                      .unsetReviseMark()
                      .setTextSelection({
                        from: 1,
                        to: 1,
                      })
                      .run();
                  }}
                  selectedText={selectedText}
                />
              </div>
            </DialogContent>
          </DialogPortal>
        </Dialog>
      )}
    </>
  );
};
