import { Editor, JSONContent } from "@tiptap/react";
import { Sparkle } from "lucide-react";
import { useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { TNotesList } from "../../../pages-v2/api/fetchNotes";
import { Button } from "../../Button";
import { LucideIcon } from "../../LucideIcon";
import { Shortcut } from "../../Shortcut";
import {
  getReviseMarksFromDocumentRecursive,
  ReviseMarkDisplaySchema,
} from "./helpers";
import { ReviseCards } from "./ReviseCards";

export const Revise = ({
  note,
  editor,
}: {
  note: TNotesList[0];
  editor: Editor | null;
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [flashcards, setFlashcards] = useState<
    {
      [question: string]: ReviseMarkDisplaySchema;
    }[]
  >([]);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    function parseRevisionNotes() {
      const parsedNote: JSONContent = JSON.parse(note.body);

      setIsLoading(true);

      setFlashcards(
        getReviseMarksFromDocumentRecursive({
          DOM: parsedNote,
        })
      );
      setIsLoading(false);
    }

    if (note.body) {
      parseRevisionNotes();
    }

    return () => {
      setIsLoading(false);
    };
  }, [note]);

  useHotkeys("alt+R", () => setOpen(!open));

  if (!flashcards.length) {
    return null;
  }

  const flashcardsList: Array<ReviseMarkDisplaySchema & { question: string }> =
    flashcards.reduce((acc, f) => {
      Object.keys(f).forEach((question) => {
        acc.push({
          question,
          answer: f[question].answer,
          color: f[question].color,
          id: f[question].id,
          retained: f[question].retained,
          forgotten: f[question].forgotten,
          revisionId: f[question].revisionId,
        });
      });
      return acc;
    }, []);

  return (
    <>
      <Shortcut
        trigger={
          <Button
            variant="ghost"
            onClick={() => setOpen(true)}
            loading={isLoading}
            className="gap-1 group"
            size="icon"
          >
            <LucideIcon icon={Sparkle} size={16} />
          </Button>
        }
        label="Revise this note"
        shortcutKey=""
      />
      <ReviseCards
        flashcards={flashcardsList || []}
        open={open}
        setOpen={setOpen}
        editor={editor}
      />
    </>
  );
};
