import { useColorMode, useColorModeValue } from "@chakra-ui/react";
import { ArrowBigUp, ArrowRight, ChevronUp, Moon, Sun } from "lucide-react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { useSupabaseSession } from "../pages-v2/hooks/queries/useSupabaseSession";
import { APP_NAME, BASE_URL } from "../pages-v2/NotesDashboardWrapper";
import { useAuthStore } from "../store/auth";
import { Button } from "../ui-components/Button";
import { LucideIcon } from "../ui-components/LucideIcon";
import { Separator } from "../ui-components/Separator";
import { ShortcutKey } from "../ui-components/Shortcut";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui-components/Tooltip";
import { APP_PAGE_HEIGHT } from "../utils/constants";
import Logo from "../utils/logo";
import { cn } from "../utils/utils";

export const RootLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex flex-col h-screen overflow-auto px-2 md:p-0">
      <Navbar />
      <div className={APP_PAGE_HEIGHT}>
        {children} <Footer />
      </div>
    </div>
  );
};

const Footer = () => {
  return (
    <section
      id="footer"
      className="border-t border-border-secondary-light dark:border-border-secondary-dark mx-auto p-2 md:px-4 dark:bg-transparent md:py-2.5 flex justify-around items-start"
    >
      <div className="flex flex-row gap-4 items-center">
        <div className="flex flex-col">
          <div className="flex flex-row items-center gap-x-1.5">
            <Logo /> {APP_NAME}
          </div>
        </div>
      </div>
      <div className="flex items-center gap-2 mt-1">
        <Button
          variant="outline"
          className="border-0 pointer-events-none text-textColors-secondary-light dark:text-textColors-secondary-dark hover:text-textColors-background-light dark:hover:text-textColors-background-dark"
        >
          All rights reserved © 2024
        </Button>
      </div>
    </section>
  );
};

const Navbar = () => {
  const history = useHistory();
  const location = useLocation();
  const { userId } = useAuthStore();

  const { data: session } = useSupabaseSession();

  const { toggleColorMode } = useColorMode();
  const mode = useColorModeValue("light", "dark");

  const hasSession = Boolean(userId) && Boolean(session?.session);

  if (location.hash.startsWith("#access_token")) {
    return (
      <Redirect
        to={{
          pathname: "/complete-profile",
          hash: location.hash,
          state: {
            access_token: new URLSearchParams(location.hash).get(
              "#access_token"
            ),
          },
        }}
      />
    );
  }

  return (
    <>
      {hasSession && history.location.pathname === "/" && (
        <div className="flex flex-row gap-2 items-center mx-auto mt-4 px-3 py-1.5 bg-backgroundColors-gray-light dark:bg-backgroundColors-gray-dark rounded-md">
          <span className="text-xs leading-relaxed">
            You are already logged in
          </span>
          <Button
            className="gap-1 h-6"
            size="sm"
            onClick={() => {
              history.push(`${BASE_URL}/notes`);
            }}
          >
            Continue
            <LucideIcon icon={ArrowRight} className="stroke-white" />
          </Button>
        </div>
      )}
      <div className="sticky top-0 z-50 bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark flex flex-row items-center justify-between px-4 py-2 border-b-border-secondary-light dark:border-b-border-secondary-dark">
        <div className="flex flex-row items-center gap-x-2">
          <Logo onClick={() => history.push(`/`)} />
          <span className="text-textColors-background-light lowercase dark:text-textColors-background-dark font-medium tracking-tight sm:text-md text-lg">
            {APP_NAME}
          </span>
        </div>
        <div className="flex flex-row items-center space-x-4">
          <Button
            variant="link"
            className={cn(
              "font-medium hover:no-underline",
              "text-textColors-secondary-light dark:text-textColors-secondary-dark hover:text-textColors-background-light dark:hover:text-textColors-background-dark"
            )}
            onClick={() => {
              history.push("/about");
            }}
          >
            Product
          </Button>
          <Button
            variant="link"
            className={cn(
              "font-medium hover:no-underline",
              "text-textColors-secondary-light dark:text-textColors-secondary-dark hover:text-textColors-background-light dark:hover:text-textColors-background-dark"
            )}
            onClick={() => {
              history.push("/blog");
            }}
          >
            Blog
          </Button>
          <Separator orientation="vertical" className="h-4" />

          <TooltipProvider>
            <Tooltip defaultOpen={false}>
              <TooltipTrigger asChild onFocus={(e) => e.preventDefault()}>
                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => {
                    toggleColorMode();
                  }}
                >
                  {mode === "dark" ? (
                    <LucideIcon icon={Sun} size={14} />
                  ) : (
                    <LucideIcon icon={Moon} size={14} />
                  )}
                </Button>
              </TooltipTrigger>
              <TooltipContent
                className={cn(
                  "flex flex-row gap-2 items-center bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark",
                  "text-textColors-background-light dark:text-textColors-background-dark",
                  "border border-border-primary-light/80 dark:border-border-primary-dark/80"
                )}
              >
                Toggle theme
                <div className="flex items-center gap-1">
                  <ShortcutKey tooltipLabel="ctrl">
                    <ChevronUp size={14} className="stroke-[#999999]" />
                  </ShortcutKey>
                  <ShortcutKey tooltipLabel="shift">
                    <ArrowBigUp size={14} className="stroke-[#999999]" />
                  </ShortcutKey>
                  <ShortcutKey tooltipLabel="T">T</ShortcutKey>
                </div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
    </>
  );
};
