import { Loader, RotateCw, WandSparkles } from "lucide-react";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import { z } from "zod";
import { AiIcon } from "../../../assets/svg/ai.svg";
import {
  getIsActiveIntegration,
  getIsOpenAIIntegration,
} from "../../../pages-v2/helpers";
import { useFetchAICompletions } from "../../../pages-v2/hooks/ai/useFetchAiCompletions";
import { useGetIntegrations } from "../../../pages-v2/hooks/queries/useGetIntegration";
import { GptIcon } from "../../../pages-v2/integrations/gpt.svg";
import { useAuthStore } from "../../../store/auth";
import { Button } from "../../../ui-components/Button";
import { LucideIcon } from "../../../ui-components/LucideIcon";
import { AiMarkerForm } from "./AiMarkerForm";

type AIMarkerGeneratorProps = {
  selectedText: string;
  onDelete: () => void;
  onSubmit: (values: { question: string; answer: string }[]) => void;
};

const generateRevisionMarkerPrompt = ({
  selectedText,
}: {
  selectedText: string;
}) => {
  return `You're an AI designed to encourage deep reflection and understanding of textual content. 
  Your task is to generate a meaningful, thought-provoking question from the provided paragraph to aid in comprehension and retention.
  Frame the question to cover key ideas, underlying themes, or subtle implications in the text. Additionally, provide a concise answer
  that directly responds to the question without referencing the text or paragraph.

  Here is the paragraph for which you'll create the question:

  [Paragraph: ${selectedText}]`;
};

export const OpenaiMarkerGenerator: FC<AIMarkerGeneratorProps> = ({
  selectedText,
  onDelete,
  onSubmit,
}) => {
  const { userId } = useAuthStore();
  const {
    data: integrations = [],
    isLoading: isLoadingIntegrations,
    isError: isErrorIntegrations,
  } = useGetIntegrations({ userId });

  const activeIntegration = getIsActiveIntegration({ integrations });

  const {
    data: openAIResponse,
    isLoading: isLoadingOpenAIResponse,
    isError: isErrorOpenAIResponse,
    isFetching: isFetchingOpenAIResponse,
    refetch: refetchOpenAIResponse,
    error,
  } = useFetchAICompletions({
    // TODO: Type this properly.
    provider: (activeIntegration.metadata as any)?.integrationType,
    params: {
      model: (activeIntegration.metadata as any)?.model?.id,
      prompt: generateRevisionMarkerPrompt({ selectedText }),
      schema: z.object({
        results: z
          .object({
            question: z.string(),
            answer: z.string(),
          })
          .array(),
      }),
    },
  });

  if (isLoadingIntegrations) {
    return null;
  }

  if (isLoadingOpenAIResponse || isFetchingOpenAIResponse) {
    return (
      <div className="flex flex-col gap-12 my-2 justify-center pr-6">
        <div className="mt-[0px] flex flex-col items-center gap-4">
          <GptIcon className="size-16" />
        </div>
        <div className="flex flex-col gap-4 pl-10">
          <div className="flex flex-col gap-4">
            <div className="flex flex-row justify-between">
              <div className="flex flex-row gap-4 items-center -ml-10">
                <Button
                  size="icon"
                  variant="outline"
                  className="size-6 rounded-md pointer-events-none"
                >
                  <AiIcon className="dark:fill-white size-3" />
                </Button>
                <div className="flex gap-2 items-baseline text-textColors-muted-light dark:text-textColors-muted-dark">
                  <span className="font-medium">Reflecting</span>
                  <div className="flex flex-row gap-1">
                    <div className="size-1 rounded-full bg-textColors-muted-light dark:bg-textColors-muted-dark animate-bounce"></div>
                    <div className="size-1 rounded-full bg-textColors-muted-light dark:bg-textColors-muted-dark animate-bounce [animation-delay:-.3s]"></div>
                    <div className="size-1 rounded-full bg-textColors-muted-light dark:bg-textColors-muted-dark animate-bounce [animation-delay:-.5s]"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!activeIntegration) {
    return (
      <div className="my-2 flex flex-col gap-4 items-center justify-center h-full pr-6">
        <GptIcon />
        <div className="flex flex-col items-center gap-6">
          <div className="rounded-md text-center">
            Unable to find an active AI integration. Please try again later.
          </div>
          <Button
            onClick={() => {
              if (activeIntegration) {
                refetchOpenAIResponse();
              }
            }}
            variant="outline"
            className="gap-1"
          >
            <LucideIcon icon={RotateCw} className="size-3" />
            Retry
          </Button>
        </div>
      </div>
    );
  }

  if (isErrorOpenAIResponse || isErrorIntegrations) {
    return (
      <div className="flex flex-col justify-center h-full pr-6">
        <AIErrorMessage
          customMessage={
            (error as any)?.message || "Failed to fetch AI response"
          }
        />
      </div>
    );
  }

  if (!openAIResponse) {
    return (
      <div className="my-2 flex flex-col gap-4 items-center justify-center h-full">
        <GptIcon />
        <div className="flex flex-col items-center gap-6 w-3/5">
          <div className="rounded-md text-center">
            Failed to generate a response. Please try again later.
          </div>
          <Button
            onClick={() => {
              if (activeIntegration) {
                refetchOpenAIResponse();
              }
            }}
            variant="outline"
            className="gap-1"
          >
            <LucideIcon icon={WandSparkles} className="size-3" />
            Regenerate
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="my-2 h-full">
      <AiMarkerForm
        aiRevisionMarkers={
          openAIResponse
            ? (
                (openAIResponse as any).object as {
                  results: Array<{
                    question: string;
                    answer: string;
                  }>;
                }
              )?.results || []
            : []
        }
        onSubmit={onSubmit}
        onDelete={onDelete}
        onRetry={refetchOpenAIResponse}
      />
    </div>
  );
};

export const AIMarkerGeneratorWrapper = ({
  selectedText,
  onSubmit,
  onDelete,
}: {
  selectedText: string;
  onSubmit: (values: { question: string; answer: string }[]) => void;
  onDelete: () => void;
}) => {
  const router = useHistory();
  const { userId } = useAuthStore();
  const {
    data: integrations = [],
    isLoading: isLoadingIntegrations,
    isError: isErrorIntegrations,
  } = useGetIntegrations({ userId });

  if (isLoadingIntegrations) {
    return (
      <div className={`grid place-items-center justify-center h-48`}>
        <div className="flex flex-row items-center gap-x-2 text-md text-textColors-secondary-light dark:text-textColors-secondary-dark">
          <Loader
            size={16}
            className="animate-spin transition-all duration-1200"
          />
        </div>
      </div>
    );
  }

  const activeIntegration = getIsActiveIntegration({ integrations });
  const isGptIntegration = getIsOpenAIIntegration({
    integration: activeIntegration,
  });

  if (!activeIntegration) {
    return (
      <div className="my-2 flex flex-col gap-2 items-center">
        <GptIcon />
        <div className="flex flex-col items-center gap-2">
          <div className="rounded-md text-center">
            You need to connect an AI integration to generate markers.
          </div>
          <Button
            onClick={() => {
              router.push("/integrations");
            }}
            variant="link"
          >
            Create new
          </Button>
        </div>
      </div>
    );
  }

  if (isGptIntegration && !isErrorIntegrations) {
    return (
      <OpenaiMarkerGenerator
        selectedText={selectedText}
        onDelete={onDelete}
        onSubmit={onSubmit}
      />
    );
  }

  return <AIErrorMessage />;
};

const AIErrorMessage = ({ customMessage }: { customMessage?: string }) => {
  return (
    <div className="my-2 flex flex-col gap-2 items-center justify-center h-full">
      <GptIcon />
      <div className="flex flex-col items-center gap-2">
        <div className="rounded-md text-center w-3/5">
          There was an error with the response from AI. Please add the
          reflection manually or retry.
        </div>
      </div>
    </div>
  );
};
