import { Editor } from "@tiptap/react";
import { ChevronDown, Pen, Sparkle } from "lucide-react";
import { FC, useState } from "react";
import { AiIcon } from "../../../assets/svg/ai.svg";
import { Button } from "../../../ui-components/Button";
import { LucideIcon } from "../../../ui-components/LucideIcon";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../ui-components/Popover";
import { cn } from "../../../utils/utils";
import { SelectionMenuType } from "../BubbleMenu";

interface RevisionPopoverProps {
  editor: Editor;
  containerRef: HTMLElement;
  setDialog: (dialog: "create" | "preview" | "ai" | null) => void;
  setSelectionType: (type: SelectionMenuType) => void;
}

export const RevisionPopover: FC<RevisionPopoverProps> = ({
  editor,
  containerRef,
  setDialog,
  setSelectionType,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Popover open={isOpen} modal>
        {/* <div className="relative h-full"> */}
        <PopoverTrigger onClick={() => setIsOpen(!isOpen)}>
          Reflect
          <ChevronDown
            className={cn(
              "h-4 w-4 transition-all duration-400",
              isOpen ? "rotate-180" : ""
            )}
          />
        </PopoverTrigger>
        <PopoverContent
          align="end"
          className="z-[99999] my-1 flex max-h-80 w-48 flex-col overflow-hidden overflow-y-auto rounded border border-border-primary-light dark:border-border-primary-dark bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark p-1 shadow-xl animate-in fade-in slide-in-from-top-1"
          onInteractOutside={() => setTimeout(() => setIsOpen(false), 0)}
          alignOffset={-8}
          containerRef={containerRef}
          ref={containerRef as any}
        >
          <div className="flex flex-col">
            <Button
              className="flex items-center gap-2 justify-start"
              variant="ghost"
              onClick={() => {
                setDialog("ai");
                setSelectionType("reviseMark");
              }}
            >
              <AiIcon className="size-3 fill-backgroundColors-background-dark dark:fill-backgroundColors-background-light" />
              AI
            </Button>
            <Button
              className="flex items-center gap-2 justify-start"
              variant="ghost"
              type="button"
              onClick={() => {
                setDialog("create");
                setSelectionType("reviseMark");
              }}
            >
              <LucideIcon icon={Pen} className="size-3 stroke-[2.5px]" />
              Add a revision
            </Button>
            {editor.isActive("reviseMark") && (
              <Button
                className="flex items-center gap-2 justify-start"
                variant="ghost"
                onClick={() => {
                  setDialog("preview");
                  setSelectionType("reviseMark");
                }}
              >
                <LucideIcon icon={Sparkle} className="size-3" />
                Preview
              </Button>
            )}
          </div>
        </PopoverContent>
        {/* </div> */}
      </Popover>
    </>
  );
};
