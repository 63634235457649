import { zodResolver } from "@hookform/resolvers/zod";
import { EyeIcon, EyeOffIcon, Loader } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "../../ui-components/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../ui-components/DropdownMenu";
import { Input } from "../../ui-components/Input";
import { Copy } from "../../utils/copy";
import { AIProviders } from "../hooks/ai/useFetchAiCompletions";
import { useOpenAIModelList } from "../hooks/openai/useOpenAIModelList";
import { GptIcon } from "./gpt.svg";

export type GptIntegrationModel = {
  created: number;
  id: string;
  object: "model";
  owned_by: "openai" | "system";
};

export const gptIntegrationCreationFormSchema = z.object({
  apiKey: z.string().min(1).max(100),
  model: z.object({
    id: z.string(),
    object: z.literal("model"),
    created: z.number(),
    owned_by: z.union([z.literal("openai"), z.literal("system")]),
  }),
});

export const GptIntegrationForm = ({
  defaultValues,
  onSubmit,
  isUpdate,
}: {
  defaultValues?: z.infer<typeof gptIntegrationCreationFormSchema>;
  onSubmit: (payload: {
    integrationType: (typeof AIProviders)[keyof typeof AIProviders];
    metadata: { apiKey: string; model: GptIntegrationModel };
  }) => void;
  isUpdate: boolean;
}) => {
  const [selectModel, setSelectModel] = useState<boolean>(
    Boolean(defaultValues?.model)
  );
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const form = useForm<z.infer<typeof gptIntegrationCreationFormSchema>>({
    resolver: zodResolver(gptIntegrationCreationFormSchema),
    defaultValues: defaultValues ?? {
      apiKey: "",
      model: null,
    },
  });
  const { apiKey, model } = form.watch();

  const {
    data: models = [],
    isLoading: isLoadingModels,
    isError: isErrorModels,
  } = useOpenAIModelList({ apiKey, enabled: selectModel });

  const { setValue, handleSubmit, register } = form;

  return (
    <form
      className="pr-4 flex flex-col justify-between h-full"
      onSubmit={handleSubmit(() =>
        onSubmit({
          integrationType: AIProviders.openai,
          metadata: {
            apiKey: apiKey,
            model: model as any,
          },
        })
      )}
    >
      <div className="flex flex-col gap-y-5 divide-y divide-dashed">
        <div className="flex flex-col gap-y-2">
          <span className="font-medium">Step 1</span>
          <div>
            <label htmlFor="api-key" className="text-sm">
              OpenAI API Key
            </label>
            <p className="text-sm text-textColors-secondary-light dark:text-textColors-secondary-dark">
              Don't worry, we will encrypt your API key and store it securely.
            </p>
          </div>
          <div className="flex flex-row items-center gap-1">
            <div className="relative w-full">
              <Input
                type={showPassword ? "text" : "password"}
                placeholder="Enter your API key"
                value={apiKey}
                onChange={(e) => setValue("apiKey", e.target.value)}
                {...register("apiKey")}
                autoComplete="off"
                className="pr-8"
              />
              <Button
                type="button"
                variant="ghost"
                size="sm"
                className="absolute right-0 top-0 h-full px-3 py-2 hover:bg-transparent"
                onClick={() => setShowPassword((prev) => !prev)}
              >
                {showPassword ? (
                  <EyeIcon className="h-4 w-4" aria-hidden="true" />
                ) : (
                  <EyeOffIcon className="h-4 w-4" aria-hidden="true" />
                )}
                <span className="sr-only">
                  {showPassword ? "Hide password" : "Show password"}
                </span>
              </Button>
            </div>
            <Copy
              content={apiKey}
              variant="outline"
              size={14}
              classNames="px-2 w-9 h-9"
            />
          </div>
        </div>
        <div className="flex flex-col gap-2 pt-4">
          <div>
            <span className="font-medium">Step 2</span>
            <p className="text-sm text-textColors-secondary-light dark:text-textColors-secondary-dark">
              Please enter a valid API key above and click here to fetch the
              models.
            </p>
          </div>
          {selectModel ? (
            <div className="flex flex-col gap-y-2 relative mt-4">
              <label>Selected model</label>
              <div className="flex flex-row items-center gap-1">
                <ModelSelector
                  onSelect={(selectedModel) => {
                    setValue("model", selectedModel);
                  }}
                  value={model as any}
                  models={models ?? []}
                  isLoading={isLoadingModels}
                  isError={isErrorModels}
                />
              </div>
            </div>
          ) : (
            <Button
              variant="outline"
              onClick={() => {
                setSelectModel(true);
              }}
              disabled={!apiKey}
              className="gap-1"
            >
              <GptIcon className="w-4 h-4" />
              Select a model
            </Button>
          )}
        </div>
      </div>
      <Button
        type="submit"
        className="flex items-end"
        disabled={!apiKey || !model}
      >
        {isUpdate ? `Update` : `Save`} integration
      </Button>
    </form>
  );
};

const ModelSelector = ({
  onSelect,
  models,
  isLoading,
  isError,
  value,
}: {
  onSelect: (model: GptIntegrationModel) => void;
  models: GptIntegrationModel[];
  isLoading: boolean;
  isError: boolean;
  value: GptIntegrationModel;
}) => {
  if (isLoading) {
    return (
      <div className="">
        <Loader
          size={16}
          className="animate-spin transition-all duration-1200"
        />
      </div>
    );
  }

  if (isError) {
    return <div>There was an error fetching the models</div>;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="gap-1">
          <GptIcon className="w-5 h-5" />
          <span className="truncate">
            {models?.find((model) => model.id === value?.id)?.id}
          </span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="h-48 overflow-auto">
        {models?.map((m) => {
          return (
            <DropdownMenuItem key={m.id} onClick={() => onSelect(m)}>
              <div>{m.id}</div>
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
