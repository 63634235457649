import { RootLayout } from "../../layouts/Root.layout";
import { Separator } from "../../ui-components/Separator";
import { Prose } from "../../ui-components/templates/Prose";

export const About = () => {
  return (
    <RootLayout>
      <div className="flex flex-col items-center py-12">
        <Prose>
          <h3>Product</h3>
          <p>
            Envise began as a leisure project but has now evolved into a
            solution to assist students and professionals in revising notes and
            learning more efficiently. We have attempted to make the platform as
            simple and straightforward as possible, so you may focus on learning
            rather than the tool itself.
          </p>
          <p>
            We utilize visual cues to help you reflect on and understand the
            information better. We additionally provide features that allow you
            to assess your knowledge and track your progress. We hope you love
            Envise as much as we enjoyed building it.
          </p>
          <Separator className="mt-12" />
          <h3>Data collection</h3>
          <ul>
            <li>
              <strong>Registration information:</strong> We collect personal
              and/or business information that you provide when you register for
              an account on the Site. This information may include your name,
              email address, GitHub username, Google email. We use this
              information to administer your account, provide you with the
              relevant services and information, communicate with you regarding
              your account, the Site. We do not also use this information to
              send you marketing communications, promotional messages, and other
              information. Your password is encrypted and stored securely in our
              database.
            </li>
            <li>
              If you use the AI feature, then we will require your OpenAI API
              key which we encrypt and store in our database. We will use this
              key to make requests to the OpenAI API on your behalf.
            </li>
            <li>
              <strong>Communications:</strong> We only send emails to the
              registered account for password reset and account recovery. We do
              not send any marketing emails or promotional messages.
            </li>
            <li>
              <strong>Product usage and Logging:</strong> We collect product
              usage data through Posthog and monitor the usage for bugs or
              errors. We do not collect any personal information through this
              process and all the sessions are anonymous.
            </li>
            <li>
              All of your notes and data are stored in our database and are not
              shared with any third-party services. We do not use your data for
              any other purposes other than to provide you with the services we
              offer.
            </li>
          </ul>
          <Separator className="mt-12" />
          <h3>Payment information</h3>
          <p>
            As mentioned above, Envise is a leisure project and we do not charge
            for the services we provide or collect any payment information from
            you.
          </p>
          <section>
            <Separator className="mt-12" />
            <h3>Team</h3>
            <p>
              Envise is created by a team of two developers,{" "}
              <a
                href="https://github.com/aquibbaig"
                target="_blank"
                rel="noreferrer"
              >
                Aquib Baig{" "}
              </a>
              and{" "}
              <a
                href="https://github.com/ani-sha"
                target="_blank"
                rel="noreferrer"
              >
                Anisha Mohanty
              </a>
              .
            </p>
            <p>
              We are both proffessional developers and are passionate about
              building products that help people learn and grow. We are always
              looking for feedback and suggestions to improve the platform. If
              you have any feedback or suggestions, please feel free to reach
              out to us at <strong>enviseapp@gmail.com</strong>.
            </p>
          </section>
        </Prose>
      </div>
    </RootLayout>
  );
};
