import { JSONContent } from "@tiptap/react";
import { DEFAULT_REVISION_CARD_COLOR } from "../../../text-editor/extensions/revise-marks/Revise.mark";

export type ReviseMarkDisplaySchema = {
  answer: string;
  color: string;
  id: string;
  retained: number;
  forgotten: number;
  revisionId: string;
};

export const getReviseMarksFromDocumentRecursive = ({
  DOM,
}: {
  DOM: JSONContent;
}): {
  [question: string]: ReviseMarkDisplaySchema;
}[] => {
  if (!DOM || typeof DOM !== "object") {
    return [];
  }

  let reviseMarks: {
    [question: string]: ReviseMarkDisplaySchema;
  }[] = [];

  DOM.content.map((item: JSONContent, idx: number) => {
    const markElements =
      (item.content ?? []).filter((i: JSONContent) => i.marks) ?? [];

    const reviseMarkElementsObject = markElements
      .filter(
        (m) =>
          m.marks?.[0]?.type === "reviseMark" &&
          Object.keys(m.marks?.[0]?.attrs?.revision || []).length > 0
      )
      .reduce((acc, el) => {
        if (el?.marks?.[0]?.attrs?.revision) {
          const { revision: revisionData } = el?.marks[0]?.attrs || {};
          const { data: revision } = revisionData || {};

          Object.entries(revision || {}).map(([question, data]) => {
            acc = {
              ...acc,
              [question]: {
                answer: (data as any).answer,
                color: el.marks[0].attrs.color || DEFAULT_REVISION_CARD_COLOR,
                id: (data as any)?.id,
                retained: parseInt((data as any)?.retained, 10) || 0,
                forgotten: parseInt((data as any)?.forgotten, 10) || 0,
                revisionId: el.marks[0].attrs.revisionId,
              },
            };

            return acc;
          });
        }

        return acc;
      }, {});

    const reviseMarkElements = Object.entries(reviseMarkElementsObject).map(
      ([question, { answer, color, id, retained, forgotten, revisionId }]) => ({
        [question]: {
          answer,
          color,
          id,
          retained,
          forgotten,
          revisionId,
        },
      })
    );

    reviseMarks = reviseMarks.concat(reviseMarkElements);

    if (item.content) {
      const nestedReviseMarks = getReviseMarksFromDocumentRecursive({
        DOM: item,
      });

      reviseMarks = reviseMarks.concat(nestedReviseMarks);
    }

    return item;
  });

  return (
    reviseMarks.reduce((acc, mark) => {
      if (
        !acc.find(
          (m: { [question: string]: ReviseMarkDisplaySchema }) =>
            Object.values(mark)[0]?.id === Object.values(m)[0]?.id
        )
      ) {
        acc.push(mark);
      }

      return acc;
    }, []) ?? []
  );
};
