import { useColorMode, useColorModeValue } from "@chakra-ui/react";
import isElectron from "is-electron";
import {
  ArrowBigUp,
  ArrowLeft,
  ArrowRight,
  ChevronUp,
  CircleUser,
  Command,
  Inbox,
  LogOut,
  Menu,
  Moon,
  Option,
  Palette,
  RotateCw,
  Sun,
  UserCog,
  X,
} from "lucide-react";
import { FC, useContext, useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useQueryClient } from "react-query";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../layouts/Auth.context";
import { useAuthStore } from "../store/auth";
import { EmptyNoteState } from "../templates/notes/helpers";
import { Button } from "../ui-components/Button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogOverlay,
  DialogTitle,
} from "../ui-components/Dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui-components/DropdownMenu";
import { LucideIcon } from "../ui-components/LucideIcon";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../ui-components/Resizable";
import { Sheet, SheetContent, SheetTrigger } from "../ui-components/Sheet";
import { ShortcutKey } from "../ui-components/Shortcut";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui-components/Tooltip";
import {
  APP_PAGE_HEIGHT,
  AvatarsMap,
  KeyboardShortCuts,
} from "../utils/constants";
import { getAvatarIcon } from "../utils/helpers";
import Logo from "../utils/logo";
import supabase from "../utils/supabase";
import { cn, getShortcutIcon } from "../utils/utils";
import { AccountSettings } from "./account-settings/AccountSettings";
import { ArchivedNotes } from "./archived/ArchivedNotes";
import { FavoriteNotes } from "./favorite-notes/FavoriteNotes";
import { AppLoader } from "./global-helpers";
import { getAIInstance } from "./helpers";
import { useNotesList } from "./hooks/queries/useNotesList";
import { useUser } from "./hooks/queries/useUser";
import { AIContextProvider } from "./integrations/AIContext";
import { Integrations } from "./integrations/Integrations";
import { useActiveIntegration } from "./integrations/useActiveIntegration";
import { NoteDetailsWrapper } from "./note-details/NoteDetails";
import { NotesSidebar } from "./notes-sidebar/NotesSidebar";
import { constructUrlForNote } from "./notes-sidebar/services";
import { SnippetsManager } from "./snippets-manager/SnippetsManager";

export const BASE_URL = "";
export const APP_URL = process.env.REACT_APP_DEPLOY_ENDPOINT;

export const NotesDashboardWrapper = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const {
    data: activeIntegration,
    isLoading: isLoadingActiveIntegration,
    isError: isErrorActiveIntegration,
  } = useActiveIntegration();

  const { userId, hydrating } = useAuthStore();
  const {
    data: [user] = [],
    isLoading: isLoadingUser,
    isError: isErrorUser,
  } = useUser({ userId });

  if (isLoadingActiveIntegration) {
    return (
      <Layout>
        <div className="grid place-items-center h-full">
          <AppLoader />
        </div>
      </Layout>
    );
  }

  if (hydrating) {
    if (isLoadingUser) {
      return (
        <Layout>
          <div className="grid place-items-center h-full">
            <AppLoader />
          </div>
        </Layout>
      );
    }

    if (isErrorUser || !user) {
      return <Redirect to="/complete-profile" />;
    }

    return null;
  }

  if (!userId)
    return (
      <Layout>
        <div className="grid place-items-center h-full">
          <AppLoader />
        </div>
      </Layout>
    );

  return (
    <Layout>
      <AIContextProvider
        instance={
          isErrorActiveIntegration ? null : getAIInstance({ activeIntegration })
        }
      >
        <ResizablePanelGroup direction="horizontal">
          <ResizablePanel
            defaultSize={20}
            minSize={16}
            maxSize={24}
            className="hidden md:block"
          >
            <div className="col-span-4 border-r border-border-secondary-light dark:border-border-secondary-dark">
              <NotesSidebar />
            </div>
          </ResizablePanel>
          <ResizableHandle withHandle className="opacity-0 md:opacity-100" />
          <ResizablePanel>
            <div
              className={`md:px-4 py-4 col-span-10 md:col-span-8 flex flex-col gap-y-2 ${APP_PAGE_HEIGHT} overflow-x-hidden overflow-auto`}
            >
              <div className="md:hidden">
                <Sheet open={sidebarOpen}>
                  <SheetTrigger asChild>
                    <Button
                      variant="outline"
                      size="icon"
                      className="h-6 w-6 ml-2"
                      onClick={() => {
                        setSidebarOpen(!sidebarOpen);
                      }}
                    >
                      <LucideIcon icon={Menu} size={14} />
                    </Button>
                  </SheetTrigger>
                  <SheetContent
                    side="left"
                    className="pr-0 pl-0"
                    autoClose={false}
                  >
                    <>
                      <div className="absolute left-4 top-3">
                        <Logo onClick={() => setSidebarOpen(false)} />
                      </div>
                      <Button
                        variant="outline"
                        size="icon"
                        className="h-6 w-6 absolute right-4 top-4"
                        onClick={() => {
                          setSidebarOpen(false);
                        }}
                      >
                        <X className="h-4 w-4" />
                      </Button>
                      <span className="sr-only">Close</span>
                      <div className="pt-6">
                        <NotesSidebar
                          onSelect={() => {
                            setSidebarOpen(false);
                          }}
                        />
                      </div>
                    </>
                  </SheetContent>
                </Sheet>
              </div>
              <Route path={`${BASE_URL}/favorites`}>
                <FavoriteNotes />
              </Route>
              <Route path={`${BASE_URL}/snippets`}>
                <SnippetsManager />
              </Route>
              <Route path={`${BASE_URL}/integrations`}>
                <Integrations />
              </Route>
              <Route path={`${BASE_URL}/archived`}>
                <ArchivedNotes />
              </Route>
              <Route path={`${BASE_URL}/inbox`}>
                <div
                  className={cn(
                    "grid place-items-center justify-center",
                    APP_PAGE_HEIGHT
                  )}
                >
                  <div className="flex flex-col gap-y-1.5 items-center text-center">
                    <LucideIcon
                      icon={Inbox}
                      size={64}
                      strokeWidth="1px"
                      className="text-text-tertiary-light dark:text-text-tertiary-dark"
                    />
                    <span className="text-textColors-muted-light dark:text-textColors-muted-dark">
                      No messages to show, check <br /> back again later
                    </span>
                  </div>
                </div>
              </Route>
              <Route path={`${BASE_URL}/notes/:noteId`}>
                <NoteDetailsWrapper />
              </Route>
              <Route path={`${BASE_URL}/settings`}>
                <AccountSettings />
              </Route>
              <Route path={`${BASE_URL}/notes`}>
                <NoteRedirector />
              </Route>
            </div>
          </ResizablePanel>
        </ResizablePanelGroup>
      </AIContextProvider>
    </Layout>
  );
};

export const APP_NAME = `envise`;

export const MAILBOX = process.env.REACT_APP_MAILBOX;
export const ENVISE_MAILBOX = process.env.REACT_APP_ENVISE_MAILBOX;

export const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex flex-col h-screen overflow-auto px-2 md:p-0">
      <Navbar />
      <div className={APP_PAGE_HEIGHT}>{children}</div>
    </div>
  );
};

const Navbar = () => {
  const router = useHistory();
  const [openShortcutModal, setOpenShortcutModal] = useState(false);

  useHotkeys("alt+Q", () => setOpenShortcutModal(true));

  return (
    <div className="flex flex-row items-center justify-between border-b md:px-4 px-1 py-2 border-b-border-secondary-light dark:border-b-border-secondary-dark">
      <div className="flex flex-row items-center gap-x-4">
        <Logo
          onClick={() => {
            router.push("/");
          }}
        />
        {isElectron() && (
          <div className="flex items-center">
            <div className="flex items-center">
              <Button
                variant="ghost"
                size="icon"
                onClick={() => router.goBack()}
              >
                <LucideIcon
                  icon={ArrowLeft}
                  size={14}
                  className="stroke-text-textColors-muted-light dark:text-textColors-muted-dark"
                />
              </Button>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => router.goForward()}
              >
                <LucideIcon
                  icon={ArrowRight}
                  size={14}
                  className="stroke-text-textColors-muted-light dark:text-textColors-muted-dark"
                />
              </Button>
            </div>
            <Button
              variant="ghost"
              size="icon"
              onClick={() => window.location.reload()}
            >
              <LucideIcon
                icon={RotateCw}
                size={14}
                className="stroke-text-textColors-muted-light dark:text-textColors-muted-dark"
              />
            </Button>
          </div>
        )}
        {/* <div className="flex items-start ml-48">
          <Button
            variant="ghost"
            size="icon"
            className="w-4"
            onClick={() => router.goBack()}
          >
            <LucideIcon icon={ChevronLeft} size={14} />
          </Button>
          <Button
            variant="ghost"
            size="icon"
            className="w-4"
            onClick={() => router.goForward()}
          >
            <LucideIcon icon={ChevronRight} size={14} />
          </Button>
        </div> */}
      </div>
      <div className="flex items-center gap-4">
        {/* <FeedbackButton /> */}

        <UserActionsDropdown
          onOpenShortcutModal={() => {
            setOpenShortcutModal(true);
          }}
        />
        <Dialog open={openShortcutModal} onOpenChange={setOpenShortcutModal}>
          <DialogOverlay />
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Shortcuts</DialogTitle>
            </DialogHeader>
            <ShortcutsList />
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

const ShortcutsList = () => {
  const keyboardShortcutsByGroup = KeyboardShortCuts.reduce<{
    [k in (typeof KeyboardShortCuts)[number]["group"]]: typeof KeyboardShortCuts;
  }>((acc, curr) => {
    if (!acc[curr.group]) {
      acc[curr.group] = [curr];
    } else {
      acc[curr?.group].push(curr);
    }

    return acc;
  }, {} as any);

  return (
    <div className="flex flex-col gap-6">
      {Object.keys(keyboardShortcutsByGroup).map((group) => {
        return (
          <div key={group} className="flex flex-col gap-1 relative">
            <div
              className={cn(
                "text-textColors-muted-light dark:text-textColors-muted-dark capitalize",
                "after:content-[' '] after:absolute after:right-0 after:top-3 after:rounded after:h-[1px] after:w-[calc(100%-100px)] after:bg-border-primary-light/50 dark:after:bg-border-primary-dark"
              )}
            >
              {group}
            </div>
            <div className="flex flex-col gap-1">
              {keyboardShortcutsByGroup[
                group as unknown as (typeof KeyboardShortCuts)[number]["group"]
              ]?.map((shortcut) => {
                return (
                  <div className="flex flex-row items-center justify-between py-1">
                    <span>{shortcut.label}</span>
                    <div className="text-textColors-muted-light dark:text-textColors-muted-dark flex flex-row items-center gap-1">
                      {shortcut.mappings.map(getShortcutIcon)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const UserActionsDropdown = ({
  onOpenShortcutModal,
}: {
  onOpenShortcutModal?: () => void;
}) => {
  const { toggleColorMode } = useColorMode();
  const mode = useColorModeValue("light", "dark");
  const text = useColorModeValue("Dark", "Light");
  const { userId, removeUser } = useAuthStore();
  const queryClient = useQueryClient();
  const {
    data: [user] = [],
    isLoading: isLoadingUser,
    isError: isErrorUser,
  } = useUser({ userId });

  const { session } = useContext(AuthContext);
  const history = useHistory();

  if (isLoadingUser || isErrorUser) return null;
  const hasSession = Boolean(userId) && Boolean(session);

  if (!hasSession) {
    return (
      <TooltipProvider>
        <Tooltip defaultOpen={false}>
          <TooltipTrigger asChild onFocus={(e) => e.preventDefault()}>
            <Button
              onClick={(e) => {
                toggleColorMode();
              }}
              variant="outline"
              size="icon"
            >
              {mode === "dark" ? <Sun size={16} /> : <Moon size={16} />}
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            Toggle theme
            <div className="flex items-center gap-1">
              <ShortcutKey tooltipLabel="ctrl">
                <ChevronUp size={14} className="stroke-[#999999]" />
              </ShortcutKey>
              <ShortcutKey tooltipLabel="shift">
                <ArrowBigUp size={14} className="stroke-[#999999]" />
              </ShortcutKey>
              <ShortcutKey tooltipLabel="T">T</ShortcutKey>
            </div>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }

  const handleLogout = async () => {
    try {
      removeUser();
      history.push("/");
      queryClient.clear();
      await supabase.auth.signOut();
    } catch (error) {}

    history.push("/");
  };

  return (
    <div className="flex flex-row gap-x-2">
      {Boolean(userId) && (
        <>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              {getAvatarIcon({
                className: "size-6",
                avatar: user?.avatar as keyof typeof AvatarsMap,
              })}
            </DropdownMenuTrigger>
            <DropdownMenuContent className="mr-2">
              <DropdownMenuItem className="gap-x-2 user-select-none pointer-events-none">
                {user?.name}
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                onClick={(e) => {
                  toggleColorMode();
                }}
                className="justify-between"
              >
                <div className="flex gap-1 items-center">
                  <LucideIcon icon={Palette} size={14} />
                  {text} theme
                </div>
                <div className="flex items-center gap-1">
                  <ShortcutKey tooltipLabel="option">
                    <Option size={14} className="stroke-[#999999]" />
                  </ShortcutKey>
                  <ShortcutKey tooltipLabel="T">T</ShortcutKey>
                </div>
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  history.push(`/user/${userId.replaceAll("-", "")}`);
                }}
                className="justify-between"
              >
                <div className="flex gap-1 items-center">
                  <LucideIcon icon={CircleUser} size={14} />
                  Profile
                </div>
                <div className="flex items-center gap-1">
                  <ShortcutKey tooltipLabel="option">
                    <Option size={14} className="stroke-[#999999]" />
                  </ShortcutKey>
                  <ShortcutKey tooltipLabel="P">P</ShortcutKey>
                </div>
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  history.push(`${BASE_URL}/settings/profile`);
                }}
                className="justify-between"
              >
                <div className="flex gap-1 items-center">
                  <LucideIcon icon={UserCog} size={14} />
                  User Settings
                </div>
                <div className="flex items-center gap-1">
                  <ShortcutKey tooltipLabel="option">
                    <Option size={14} className="stroke-[#999999]" />
                  </ShortcutKey>
                  <ShortcutKey tooltipLabel="S">S</ShortcutKey>
                </div>
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  onOpenShortcutModal();
                }}
                className="justify-between"
              >
                <div className="flex gap-1 items-center">
                  <LucideIcon icon={Command} size={14} />
                  Shortcuts
                </div>
                <div className="flex items-center gap-1">
                  <ShortcutKey tooltipLabel="option">
                    <Option size={14} className="stroke-[#999999]" />
                  </ShortcutKey>
                  <ShortcutKey tooltipLabel="Q">Q</ShortcutKey>
                </div>
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem onClick={() => handleLogout()}>
                <LucideIcon icon={LogOut} size={14} />
                Logout
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </>
      )}
    </div>
  );
};

const NoteRedirector: FC = () => {
  const location = useLocation();
  const router = useHistory();
  const { userId } = useAuthStore();

  const {
    data: notes = [],
    isLoading: isLoadingNotes,
    isError: isErrorNotes,
  } = useNotesList({ userId });

  // redirect to the first item in the list
  const [firstNote] = notes;

  useEffect(() => {
    if (location.pathname === `${BASE_URL}/notes`) {
      if (firstNote) {
        router.push(constructUrlForNote({ note: firstNote }));
      }
    }
  }, [firstNote, location.pathname, router]);

  if (isErrorNotes) {
    return (
      <div className="grid place-items-center h-full -mt-12">
        <EmptyNoteState />
      </div>
    );
  }

  if (isLoadingNotes) {
    return null;
  }

  if (
    (!firstNote && location.pathname.endsWith(`${BASE_URL}/notes`)) ||
    (!firstNote && location.pathname.endsWith(`${BASE_URL}/`))
  ) {
    return (
      <div className="grid place-items-center h-full -mt-12">
        <EmptyNoteState />
      </div>
    );
  }

  return null;
};
