import dayjs from "dayjs";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import { RootLayout } from "../layouts/Root.layout";
import { APP_NAME } from "../pages-v2/NotesDashboardWrapper";
import { Button } from "../ui-components/Button";
import { DAYJS_DEFAULT_FORMAT } from "../utils/utils";
import { usePosts } from "./usePosts";

export const Blog: FC = () => {
  const router = useHistory();

  const { posts } = usePosts();

  return (
    <RootLayout>
      <div className="min-h-[90%] w-full flex flex-col items-center">
        <div className="lg:w-[50%] w-full p-4 lg:p-12 flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <h2>Blog</h2>
            <p className="text-textColors-muted-light dark:text-textColors-muted-dark">
              The repository containing all miscellaneous things learned along
              the process of building {APP_NAME}.
            </p>
          </div>
          <div>
            {posts.map((file, index) => {
              const {
                frontmatter: { title, date, tag, slug },
              } = file;

              return (
                <Button
                  variant="ghost"
                  key={index}
                  className="flex w-full items-start flex-col rounded-md !px-3 !py-2 h-auto lg:-mx-3"
                  onClick={() => {
                    router.push(`blog/${slug}`);
                  }}
                >
                  <div className="text-sm text-textColors-muted-light dark:text-textColors-muted-dark">
                    {tag}
                  </div>
                  <div className="flex flex-row items-start justify-between w-full">
                    <div className="shrink">{title}</div>
                    <div className="shrink-0 mt-0.5 text-sm text-textColors-muted-light dark:text-textColors-muted-dark">
                      {dayjs(date).format(DAYJS_DEFAULT_FORMAT)}
                    </div>
                  </div>
                </Button>
              );
            })}
          </div>
        </div>
      </div>
    </RootLayout>
  );
};
