import {
  RevisionCardColors,
  RevisionCardColorsType,
} from "../../text-editor/extensions/revise-marks/Revise.mark";
import { Button } from "../../ui-components/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../ui-components/DropdownMenu";
import { Tooltip, TooltipProvider } from "../../ui-components/Tooltip";
import { cn } from "../../utils/utils";

export const RevisionColorPicker = ({
  value,
  setValue,
  showTooltip = true,
}: {
  value: RevisionCardColorsType;
  setValue: (value: RevisionCardColorsType) => void;
  showTooltip?: boolean;
}) => {
  const dropdownTrigger = showTooltip ? (
    <TooltipProvider>
      <Tooltip>
        <DropdownMenuTrigger asChild>
          <Button
            type="button"
            className="justify-center gap-1.5"
            variant="outline"
          >
            <div
              style={{
                background: "var(--" + value + ")",
              }}
              className="size-3 rounded"
            ></div>
            Highlight
          </Button>
        </DropdownMenuTrigger>
      </Tooltip>
    </TooltipProvider>
  ) : (
    <DropdownMenuTrigger asChild>
      <Button
        type="button"
        className="justify-center gap-1.5"
        variant="outline"
      >
        <div
          style={{
            background: "var(--" + value + ")",
          }}
          className="size-3 rounded"
        ></div>
        Highlight
      </Button>
    </DropdownMenuTrigger>
  );

  return (
    <DropdownMenu>
      {dropdownTrigger}
      <DropdownMenuContent className={cn("max-h-60 overflow-auto relative")}>
        {Object.keys(RevisionCardColors).map((colorKey) => {
          return (
            <DropdownMenuItem
              key={colorKey}
              onClick={() => setValue(colorKey as RevisionCardColorsType)}
              className="capitalize flex items-center gap-2 pr-24"
            >
              <div
                className="h-4 w-4 rounded-full"
                style={{
                  background:
                    "var(" +
                    RevisionCardColors[colorKey as RevisionCardColorsType]
                      .value +
                    ")",
                }}
              ></div>
              {RevisionCardColors[colorKey as RevisionCardColorsType].label}
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
