import { ThumbsUp, WandSparkles } from "lucide-react";
import { FC, useState } from "react";
import { ReactTyped } from "react-typed";
import { AiIcon } from "../../../assets/svg/ai.svg";
import { GptIcon } from "../../../pages-v2/integrations/gpt.svg";
import { Button } from "../../../ui-components/Button";
import { LucideIcon } from "../../../ui-components/LucideIcon";
import { Separator } from "../../../ui-components/Separator";
import { cn } from "../../../utils/utils";

export const AIResultSingleView: FC<{
  aiRevisionMarkers: { question: string; answer: string }[];
  onSubmit: (results: { question: string; answer: string }[]) => void;
  onDelete: () => void;
  onRetry: () => void;
}> = ({ aiRevisionMarkers, onDelete, onSubmit, onRetry }) => {
  const [isFinishedAutocomplete, setIsFinishedAutocomplete] = useState(false);

  // there is one result
  const { question, answer } = aiRevisionMarkers?.[0];
  return (
    <div className="flex flex-col gap-12 pr-6 overflow-auto h-full">
      <div className="mt-[0px] flex flex-col items-center gap-4">
        <GptIcon className="size-16" />
      </div>
      <div className="flex flex-col gap-4 pl-10 pr-4">
        <div className="bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark text-textColors-background-light dark:text-textColors-background-dark text-right flex flex-col gap-1">
          <div className="flex flex-col gap-1">
            <span className="text-left w-fit font-medium">{question}</span>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-row justify-between">
            <div className="flex flex-row gap-4 items-center -ml-10 h-8">
              <Button
                size="icon"
                variant="outline"
                className="size-6 rounded-md pointer-events-none"
              >
                <AiIcon className="dark:fill-white size-3" />
              </Button>
              <span className="font-medium">Results</span>
            </div>
            {isFinishedAutocomplete && (
              <div className="flex flex-row items-center gap-1">
                <Button
                  onClick={() => {
                    onSubmit([{ question, answer }]);
                  }}
                  variant="ghost"
                  className={cn(
                    "text-text-textColors-muted-light dark:text-textColors-muted-dark hover:no-underline gap-1",
                    "hover:text-textColors-background-light dark:hover:text-textColors-background-dark group"
                  )}
                >
                  <LucideIcon
                    icon={ThumbsUp}
                    className={cn(
                      "size-3.5 stroke-text-textColors-muted-light dark:stroke-textColors-muted-dark hover:no-underline gap-1",
                      "group-hover:stroke-textColors-background-light dark:group-hover:stroke-textColors-background-dark"
                    )}
                  />
                </Button>
                <Separator
                  orientation="vertical"
                  className="h-4 !border-border-primary-dark"
                />
                <Button
                  onClick={onRetry}
                  variant="ghost"
                  className={cn(
                    "gap-1 text-text-textColors-muted-light dark:text-textColors-muted-dark hover:no-underline",
                    "hover:text-textColors-background-light dark:hover:text-textColors-background-dark group"
                  )}
                >
                  <LucideIcon
                    icon={WandSparkles}
                    className={cn(
                      "size-3.5 stroke-text-textColors-muted-light dark:stroke-textColors-muted-dark hover:no-underline gap-1",
                      "group-hover:stroke-textColors-background-light dark:group-hover:stroke-textColors-background-dark"
                    )}
                  />
                </Button>
              </div>
            )}
          </div>
          <div className="text-textColors-secondary-light dark:text-textColors-secondary-dark">
            <ReactTyped
              backSpeed={50}
              onBegin={() => {
                setIsFinishedAutocomplete(false);
              }}
              onComplete={() => {
                setIsFinishedAutocomplete(true);
              }}
              onDestroy={function noRefCheck() {}}
              onLastStringBackspaced={function noRefCheck() {}}
              onReset={() => {
                setIsFinishedAutocomplete(false);
              }}
              onStart={function noRefCheck() {}}
              onStop={function noRefCheck() {}}
              onStringTyped={() => {
                setIsFinishedAutocomplete(false);
              }}
              onTypingPaused={function noRefCheck() {}}
              onTypingResumed={function noRefCheck() {}}
              startWhenVisible
              strings={[answer]}
              typeSpeed={10}
              showCursor={false}
              typedRef={function noRefCheck() {}}
              className="leading-7"
            />
            {isFinishedAutocomplete && (
              <div className="flex flex-row items-center gap-2 mt-6">
                <Button
                  onClick={() => {
                    onSubmit([{ question, answer }]);
                  }}
                  variant="link"
                  className={cn(
                    "pl-0 text-text-textColors-muted-light dark:text-textColors-muted-dark hover:no-underline gap-1",
                    "hover:text-textColors-background-light dark:hover:text-textColors-background-dark group"
                  )}
                >
                  <LucideIcon
                    icon={ThumbsUp}
                    className={cn(
                      "size-3.5 stroke-text-textColors-muted-light dark:stroke-textColors-muted-dark hover:no-underline gap-1",
                      "group-hover:stroke-textColors-background-light dark:group-hover:stroke-textColors-background-dark"
                    )}
                  />
                  Accept
                </Button>
                <Separator
                  orientation="vertical"
                  className="h-4 !border-border-primary-dark"
                />
                <Button
                  onClick={onRetry}
                  variant="link"
                  className={cn(
                    "gap-1 text-text-textColors-muted-light dark:text-textColors-muted-dark hover:no-underline",
                    "hover:text-textColors-background-light dark:hover:text-textColors-background-dark group"
                  )}
                >
                  <LucideIcon
                    icon={WandSparkles}
                    className={cn(
                      "size-3.5 stroke-text-textColors-muted-light dark:stroke-textColors-muted-dark hover:no-underline gap-1",
                      "group-hover:stroke-textColors-background-light dark:group-hover:stroke-textColors-background-dark"
                    )}
                  />
                  Regenerate
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
